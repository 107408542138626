export const GET_POSTS_REQ = "GET_POSTS_REQ";
export const GET_POSTS_RES = "GET_POSTS_RES";
export const GET_POSTS_ERR = "GET_POSTS_ERR";
export const GET_DESC_REQ = "GET_DESC_REQ";
export const GET_DESC_ERR = "GET_DESC_ERR";
export const GET_DESC_RES = "GET_DESC_RES";
export const GET_EN_DESC_REQ = "GET_EN_DESC_REQ";
export const GET_EN_DESC_ERR = "GET_EN_DESC_ERR";
export const GET_EN_DESC_RES = "GET_EN_DESC_RES";
export const GET_IMAGES_REQ = "GET_IMAGES_REQ";
export const GET_IMAGES_ERR = "GET_IMAGES_ERR";
export const GET_IMAGES_RES = "GET_IMAGES_RES";
export const GET_TOURDETAILS_REQ = "GET_TOURDETAILS_REQ";
export const GET_TOURDETAILS_RES = "GET_TOURDETAILS_RES";
export const GET_TOURDETAILS_ERR = "GET_TOURDETAILS_ERR";
export const GET_EN_TOUR_REQ = "GET_EN_TOUR_REQ";
export const GET_EN_TOUR_RES = "GET_EN_TOUR_RES";
export const GET_EN_TOUR_ERR = "GET_EN_TOUR_ERR";
export const GET_IMAGES_TOUR_REQ = "GET_IMAGES_TOUR_REQ";
export const GET_IMAGES_TOUR_RES = "GET_IMAGES_TOUR_RES";
export const GET_IMAGES_TOUR_ERR = "GET_IMAGES_TOUR_ERR";
export const GET_GEO_REQ = "GET_GEO_REQ";
export const GET_GEO_RES = "GET_GEO_RES";
export const GET_GEO_ERR = "GET_GEO_ERR";
export const GET_CITIES_REQ = "GET_CITIES_REQ";
export const GET_CITIES_RES = "GET_CITIES_RES";
export const GET_CITIES_ERR = "GET_CITIES_ERR";
// ##### 24-07-22 Komarov
export const GET_CITIES_OVERVIEW_TOP_REQ = "GET_CITIES_OVERVIEW_TOP_REQ";
export const GET_CITIES_OVERVIEW_TOP_RES = "GET_CITIES_OVERVIEW_TOP_RES";
export const GET_CITIES_OVERVIEW_TOP_ERR = "GET_CITIES_OVERVIEW_TOP_ERR";
export const GET_CITIES_OVERVIEW_ALL_REQ = "GET_CITIES_OVERVIEW_ALL_REQ";
export const GET_CITIES_OVERVIEW_ALL_RES = "GET_CITIES_OVERVIEW_ALL_RES";
export const GET_CITIES_OVERVIEW_ALL_ERR = "GET_CITIES_OVERVIEW_ALL_ERR";
// ##### 24-09-05 Komarov
export const GET_OCCUPIED_CITIES_TOP_REQ = "GET_OCCUPIED_CITIES_TOP_REQ";
export const GET_OCCUPIED_CITIES_TOP_RES = "GET_OCCUPIED_CITIES_TOP_RES";
export const GET_OCCUPIED_CITIES_TOP_ERR = "GET_OCCUPIED_CITIES_TOP_ERR";
export const GET_OCCUPIED_CITIES_ALL_REQ = "GET_OCCUPIED_CITIES_ALL_REQ";
export const GET_OCCUPIED_CITIES_ALL_RES = "GET_OCCUPIED_CITIES_ALL_RES";
export const GET_OCCUPIED_CITIES_ALL_ERR = "GET_OCCUPIED_CITIES_ALL_ERR";
// ##### 24-08-16 Komarov
export const GET_ATTRACTIONS_TOP_REQ = "GET_ATTRACTIONS_TOP_REQ";
export const GET_ATTRACTIONS_TOP_RES = "GET_ATTRACTIONS_TOP_RES";
export const GET_ATTRACTIONS_TOP_ERR = "GET_ATTRACTIONS_TOP_ERR";
export const GET_ATTRACTIONS_ALL_REQ = "GET_ATTRACTIONS_ALL_REQ";
export const GET_ATTRACTIONS_ALL_RES = "GET_ATTRACTIONS_ALL_RES";
export const GET_ATTRACTIONS_ALL_ERR = "GET_ATTRACTIONS_ALL_ERR";
// ##### 24-07-31 Komarov
export const GET_HOTELS_OVERVIEW_TOP_REQ = "GET_HOTELS_OVERVIEW_TOP_REQ";
export const GET_HOTELS_OVERVIEW_TOP_RES = "GET_HOTELS_OVERVIEW_TOP_RES";
export const GET_HOTELS_OVERVIEW_TOP_ERR = "GET_HOTELS_OVERVIEW_TOP_ERR";
export const GET_HOTELS_OVERVIEW_ALL_REQ = "GET_HOTELS_OVERVIEW_ALL_REQ";
export const GET_HOTELS_OVERVIEW_ALL_RES = "GET_HOTELS_OVERVIEW_ALL_RES";
export const GET_HOTELS_OVERVIEW_ALL_ERR = "GET_HOTELS_OVERVIEW_ALL_ERR";
export const GET_SITEPAGECITY_REQ = "GET_SITEPAGECITY_REQ";
export const GET_SITEPAGECITY_RES = "GET_SITEPAGECITY_RES";
export const GET_SITEPAGECITY_ERR = "GET_SITEPAGECITY_ERR";
export const GET_GENERAL_GEO_REQ = "GET_GENERAL_GEO_REQ";
export const GET_GENERAL_GEO_RES = "GET_GENERAL_GEO_RES";
export const GET_GENERAL_GEO_ERR = "GET_GENERAL_GEO_ERR";
export const GET_CONTENT_REQ = "GET_CONTENT_REQ";
export const GET_CONTENT_RES = "GET_CONTENT_RES";
export const GET_CONTENT_ERR = "GET_CONTENT_ERR";
export const GET_PAGES_REQ = "GET_PAGES_REQ";
export const GET_PAGES_RES = "GET_PAGES_RES";
export const GET_PAGES_ERR = "GET_PAGES_ERR";
// ##### 25-03-07 Komarov
export const GET_PURE_PAGE_REQ = "GET_PURE_PAGE_REQ";
export const GET_PURE_PAGE_RES = "GET_PURE_PAGE_RES";
export const GET_PURE_PAGE_ERR = "GET_PURE_PAGE_ERR";
export const GET_TOPTOURS_REQ = "GET_TOPTOURS_REQ";
export const GET_TOPTOURS_RES = "GET_TOPTOURS_RES";
export const GET_TOPTOURS_ERR = "GET_TOPTOURS_ERR";
export const GET_PAX_REQ = "GET_PAX_REQ";
export const GET_PAX_RES = "GET_PAX_RES";
export const GET_PAX_ERR = "GET_PAX_ERR";
export const GET_PAX_INNER_REQ = "GET_PAX_INNER_REQ";
export const GET_PAX_INNER_RES = "GET_PAX_INNER_RES";
export const GET_PAX_INNER_ERR = "GET_PAX_INNER_ERR";
// ##### 25-03-14 Komarov
export const GET_HOTELS_AVAIL_REQ = "GET_HOTELS_AVAIL_REQ";
export const GET_HOTELS_AVAIL_RES = "GET_HOTELS_AVAIL_RES";
export const GET_HOTELS_AVAIL_ERR = "GET_HOTELS_AVAIL_ERR";
// ##### 25-03-12 Komarov
export const GET_AUTOCOMPLETE_DATA_REQ = "GET_AUTOCOMPLETE_DATA_REQ";
export const GET_AUTOCOMPLETE_DATA_RES = "GET_AUTOCOMPLETE_DATA_RES";
export const GET_AUTOCOMPLETE_DATA_ERR = "GET_AUTOCOMPLETE_DATA_ERR";
// ##### 25-02-21 Komarov
export const RESET_HOTELS_AVAIL = "RESET_HOTELS_AVAIL";
export const GET_HOTEL_CONTENT_REQ = "GET_HOTEL_CONTENT_REQ";
export const GET_HOTEL_CONTENT_RES = "GET_HOTEL_CONTENT_RES";
export const GET_HOTEL_CONTENT_ERR = "GET_HOTEL_CONTENT_ERR";
// ##### 25-02-12 Komarov
export const GET_HOTEL_CONTENT_REQ2 = "GET_HOTEL_CONTENT_REQ2";
export const GET_HOTEL_CONTENT_RES2 = "GET_HOTEL_CONTENT_RES2";
export const GET_HOTEL_CONTENT_ERR2 = "GET_HOTEL_CONTENT_ERR2";
// ##### 25-02-15 Komarov
export const GET_HOTEL_CONTENT_REQ3 = "GET_HOTEL_CONTENT_REQ3";
export const GET_HOTEL_CONTENT_RES3 = "GET_HOTEL_CONTENT_RES3";
export const GET_HOTEL_CONTENT_ERR3 = "GET_HOTEL_CONTENT_ERR3";
export const GET_HOTEL_CONTENT_REQ4 = "GET_HOTEL_CONTENT_REQ4";
export const GET_HOTEL_CONTENT_RES4 = "GET_HOTEL_CONTENT_RES4";
export const GET_HOTEL_CONTENT_ERR4 = "GET_HOTEL_CONTENT_ERR4";
export const GET_HOTEL_RATES_REQ = "GET_HOTEL_RATES_REQ";
export const GET_HOTEL_RATES_RES = "GET_HOTEL_RATES_RES";
export const GET_HOTEL_RATES_ERR = "GET_HOTEL_RATES_ERR";
// ##### 25-02-15 Komarov
export const GET_HOTEL_RATES_REQ2 = "GET_HOTEL_RATES_REQ2";
export const GET_HOTEL_RATES_RES2 = "GET_HOTEL_RATES_RES2";
export const GET_HOTEL_RATES_ERR2 = "GET_HOTEL_RATES_ERR2";
// ##### 24-06-10 Komarov
export const RESET_HOTEL_RATES = "RESET_HOTEL_RATES";
// ##### 24-05-24 Komarov
export const GET_HOTEL_CONTENT = "GET_HOTEL_CONTENT";
export const GET_HTL_ROOM_NUMBER_REQ = "GET_HTL_ROOM_NUMBER_REQ";
export const GET_HTL_ROOM_NUMBER_RES = "GET_HTL_ROOM_NUMBER_RES";
export const GET_HTL_ROOM_NUMBER_ERR = "GET_HTL_ROOM_NUMBER_ERR";
export const GET_AVAIL_REQ = "GET_AVAIL_REQ";
export const GET_AVAIL_RES = "GET_AVAIL_RES";
export const GET_AVAIL_ERR = "GET_AVAIL_ERR";
export const GET_HTL_SEARCH_REQ = "GET_HTL_SEARCH_REQ";
export const GET_HTL_SEARCH_RES = "GET_HTL_SEARCH_RES";
export const GET_HTL_SEARCH_ERR = "GET_HTL_SEARCH_ERR";
export const GET_SEARCH_OCCUPANCY_REQ = "GET_SEARCH_OCCUPANCY_REQ";
export const GET_SEARCH_OCCUPANCY_RES = "GET_SEARCH_OCCUPANCY_RES";
export const GET_SEARCH_OCCUPANCY_ERR = "GET_SEARCH_OCCUPANCY_ERR";
export const GET_LOCALE_REQ = "GET_LOCALE_REQ";
export const GET_LOCALE_RES = "GET_LOCALE_RES";
export const GET_LOCALE_ERR = "GET_LOCALE_ERR";
export const GET_LANG_REQ = "GET_LANG_REQ";
export const GET_LANG_RES = "GET_LANG_RES";
export const GET_LANG_ERR = "GET_LANG_ERR";
export const GET_LANGS_REQ = "GET_LANGS_REQ";
export const GET_LANGS_RES = "GET_LANGS_RES";
export const GET_LANGS_ERR = "GET_LANGS_ERR";
export const GET_HOTEL_CITIES_REQ = "GET_HOTEL_CITIES_REQ";
export const GET_HOTEL_CITIES_RES = "GET_HOTEL_CITIES_RES";
export const GET_HOTEL_CITIES_ERR = "GET_HOTEL_CITIES_ERR";
export const GET_HOTEL_NAME_REQ = "GET_HOTEL_NAME_REQ";
export const GET_HOTEL_NAME_RES = "GET_HOTEL_NAME_RES";
export const GET_HOTEL_NAME_ERR = "GET_HOTEL_NAME_ERR";
export const GET_CURRENCY_REQ = "GET_CURRENCY_REQ";
export const GET_CURRENCY_RES = "GET_CURRENCY_RES";
export const GET_CURRENCY_ERR = "GET_CURRENCY_ERR";
// ##### 24-07-25 Komarov
export const SET_PROMOCODE_REQ = "SET_PROMOCODE_REQ";
export const SET_PROMOCODE_RES = "SET_PROMOCODE_RES";
export const SET_PROMOCODE_ERR = "SET_PROMOCODE_ERR";
// ##### 25-03-05 Komarov
export const SET_WHITELABEL_RES = "SET_WHITELABEL_RES";
export const SET_WHITELABEL_ERR = "SET_WHITELABEL_ERR";
// ##### 25-03-05 Komarov
export const GET_SITE_CYPHER_CODE_DATA_RES = "GET_SITE_CYPHER_CODE_DATA_RES";
export const GET_SITE_CYPHER_CODE_DATA_ERR = "GET_SITE_CYPHER_CODE_DATA_ERR";
export const GET_PICKEDCURRENCY_REQ = "GET_PICKEDCURRENCY_REQ";
export const GET_PICKEDCURRENCY_RES = "GET_PICKEDCURRENCY_RES";
export const GET_ROUTE_REQ = "GET_ROUTE_REQ";
export const GET_ROUTE_RES = "GET_ROUTE_RES";
export const GET_ROUTE_ERR = "GET_ROUTE_ERR";
export const GET_HTLCHOICE_REQ = "GET_HTLCHOICE_REQ";
export const GET_HTLCHOICE_RES = "GET_HTLCHOICE_RES";
export const GET_HTLCHOICE_ERR = "GET_HTLCHOICE_ERR";
export const GET_TOURCONTENTS_REQ = "GET_TOURCONTENTS_REQ";
export const GET_TOURCONTENTS_RES = "GET_TOURCONTENTS_RES";
export const GET_TOURCONTENTS_ERR = "GET_TOURCONTENTS_ERR";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS2 = "FETCH_USERS2";
export const FETCH_USERS3 = "FETCH_USERS3";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const SET_DEVICE_MODEL = "SET_DEVICE_MODEL";
export const SET_CURRENT_URL = "SET_CURRENT_URL";
// ##### 24-07-22 Komarov
export const SET_OTHERS_NAME = "SET_OTHERS_NAME";
export const SET_LANGUAGE = "SET_LANGUAGE";
// ##### 24-07-24 Komarov
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_DATA = "SET_DATA";
// ##### 23-10-23 Komarov
// export const SET_IP_INFO = "SET_IP_INFO";
// ##### 25-03-18 Komarov
export const GET_PURE_PAGES_SSR = "GET_PURE_PAGES_SSR";
// ##### 25-03-20 Komarov
export const GET_PURE_PAGE_ABOUT_US_SSR_REQ = "GET_PURE_PAGE_ABOUT_US_SSR_REQ";
export const GET_PURE_PAGE_ABOUT_US_SSR_RES = "GET_PURE_PAGE_ABOUT_US_SSR_RES";
export const GET_PURE_PAGE_ABOUT_US_SSR_ERR = "GET_PURE_PAGE_ABOUT_US_SSR_ERR";
// ##### 25-03-21 Komarov
export const GET_SLIDER_PAGE_SSR_REQ = "SLIDER_PAGE_SSR_REQ";
export const GET_SLIDER_PAGE_SSR_RES = "SLIDER_PAGE_SSR_RES";
export const GET_SLIDER_PAGE_SSR_ERR = "SLIDER_PAGE_SSR_ERR";
export const GET_MAIN_PAGE_SSR_REQ = "MAIN_PAGE_SSR_REQ";
export const GET_MAIN_PAGE_SSR_RES = "MAIN_PAGE_SSR_RES";
export const GET_MAIN_PAGE_SSR_ERR = "MAIN_PAGE_SSR_ERR";
export const GET_WAR_PAGE_SSR_REQ = "WAR_PAGE_SSR_REQ";
export const GET_WAR_PAGE_SSR_RES = "WAR_PAGE_SSR_RES";
export const GET_WAR_PAGE_SSR_ERR = "WAR_PAGE_SSR_ERR";
export const GET_META_CHARSET_PAGE_SSR_REQ = "META_CHARSET_PAGE_SSR_REQ";
export const GET_META_CHARSET_PAGE_SSR_RES = "META_CHARSET_PAGE_SSR_RES";
export const GET_META_CHARSET_PAGE_SSR_ERR = "META_CHARSET_PAGE_SSR_ERR";
export const GET_META_VIEWPORT_PAGE_SSR_REQ = "META_VIEWPORT_PAGE_SSR_REQ";
export const GET_META_VIEWPORT_PAGE_SSR_RES = "META_VIEWPORT_PAGE_SSR_RES";
export const GET_META_VIEWPORT_PAGE_SSR_ERR = "META_VIEWPORT_PAGE_SSR_ERR";
export const GET_META_KEYWORDS_PAGE_SSR_REQ = "META_KEYWORDS_PAGE_SSR_REQ";
export const GET_META_KEYWORDS_PAGE_SSR_RES = "META_KEYWORDS_PAGE_SSR_RES";
export const GET_META_KEYWORDS_PAGE_SSR_ERR = "META_KEYWORDS_PAGE_SSR_ERR";
export const GET_META_DESCRIPTION_PAGE_SSR_REQ =
  "META_DESCRIPTION_PAGE_SSR_REQ";
export const GET_META_DESCRIPTION_PAGE_SSR_RES =
  "META_DESCRIPTION_PAGE_SSR_RES";
export const GET_META_DESCRIPTION_PAGE_SSR_ERR =
  "META_DESCRIPTION_PAGE_SSR_ERR";
export const GET_META_TITLE_PAGE_SSR_REQ = "GET_META_TITLE_PAGE_SSR_REQ";
export const GET_META_TITLE_PAGE_SSR_RES = "GET_META_TITLE_PAGE_SSR_RES";
export const GET_META_TITLE_PAGE_SSR_ERR = "GET_META_TITLE_PAGE_SSR_ERR";
// ##### 25-04-07 Komarov
/*export const GET_PRELOADED_PAGE_REQ = "GET_PRELOADED_PAGE_REQ";
export const GET_PRELOADED_PAGE_RES = "GET_PRELOADED_PAGE_RES";
export const GET_PRELOADED_PAGE_ERR = "GET_PRELOADED_PAGE_ERR";*/
// ##### 25-04-07 Komarov
/*export const GET_PRELOADED_CITY_PAGE_REQ = "GET_PRELOADED_CITY_PAGE_REQ";
export const GET_PRELOADED_CITY_PAGE_RES = "GET_PRELOADED_CITY_PAGE_RES";
export const GET_PRELOADED_CITY_PAGE_ERR = "GET_PRELOADED_CITY_PAGE_ERR";*/

// ##### 24-11-08 Komarov
export const GET_COMPANY_RES = "GET_COMPANY_RES";
export const GET_COMPANY_NOT_FOUND = "GET_COMPANY_NOT_FOUND";
export const GET_COMPANY_ERR = "GET_COMPANY_ERR";

export const GET_TOPHOTELS_REQ = "GET_TOPHOTELS_REQ";
export const GET_TOPHOTELS_RES = "GET_TOPHOTELS_RES";
export const GET_TOPHOTELS_ERR = "GET_TOPHOTELS_ERR";
export const GET_TOGGLER_TYPE_REQ = "GET_TOGGLER_TYPE_REQ";
export const GET_TOGGLER_TYPE_RES = "GET_TOGGLER_TYPE_RES";
export const GET_TOGGLER_TYPE_ERR = "GET_TOGGLER_TYPE_ERR";

// ##### 24-05-10 Komarov
export const SAVE_HOTELS_CONTEXT = "SAVE_HOTELS_CONTEXT";
export const SET_STAY_START_DATE = "SET_STAY_START_DATE";
export const SET_STAY_END_DATE = "SET_STAY_END_DATE";
export const SET_HOTELS_VALUE = "SET_HOTELS_VALUE";
export const SET_SELECTED_ITEM_INFO = "SET_SELECTED_ITEM_INFO";
export const SET_OPEN = "SET_OPEN";
export const SET_PICKED_HOTEL_VALUE = "SET_PICKED_HOTEL_VALUE";
export const SET_CITY_OR_HOTEL_NAME_OPENED = "SET_CITY_OR_HOTEL_NAME_OPENED";
export const SET_HOTEL_DATES_DIV_OPENED = "SET_HOTEL_DATES_DIV_OPENED";
export const SET_SEARCH_CLICKED = "SET_SEARCH_CLICKED";
export const SET_FORM_FILLED = "SET_FORM_FILLED";
// ##### 24-05-13 Komarov
export const SET_PICKED_START_DATE = "SET_PICKED_START_DATE";
export const SET_PICKED_END_DATE = "SET_PICKED_END_DATE";
export const SET_COUNTER_ADULTS_OUTER = "SET_COUNTER_ADULTS_OUTER";
export const SET_COUNTER_CHILD_OUTER = "SET_COUNTER_CHILD_OUTER";
export const SET_COUNTER_ROOMS_OUTER = "SET_COUNTER_ROOMS_OUTER";
export const SET_AUTOCOMPLETE_DIV_OPENED = "SET_AUTOCOMPLETE_DIV_OPENED";
export const SET_PAX_CHOICE_DIV_OPENED = "SET_PAX_CHOICE_DIV_OPENED";

// ##### 24-05-13 Komarov
export const SAVE_HOTEL_DATES_COMPONENT_CONTEXT =
  "SAVE_HOTEL_DATES_COMPONENT_CONTEXT";
// ##### 24-05-29 Komarov
export const SET_SITE_PAGE_LIST_SECTION = "SET_SITE_PAGE_LIST_SECTION";

// ##### 24-06-17 Komarov
export const FETCH_GALLERY_BEGIN = "FETCH_GALLERY_BEGIN";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAILURE = "FETCH_GALLERY_FAILURE";
