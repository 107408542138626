// ##### 25-03-26 Komarov
import {
  GET_MAIN_PAGE_SSR_ERR,
  GET_MAIN_PAGE_SSR_REQ,
  GET_MAIN_PAGE_SSR_RES,
  GET_META_CHARSET_PAGE_SSR_ERR,
  GET_META_CHARSET_PAGE_SSR_REQ,
  GET_META_CHARSET_PAGE_SSR_RES,
  GET_META_DESCRIPTION_PAGE_SSR_ERR,
  GET_META_DESCRIPTION_PAGE_SSR_REQ,
  GET_META_DESCRIPTION_PAGE_SSR_RES,
  GET_META_KEYWORDS_PAGE_SSR_ERR,
  GET_META_KEYWORDS_PAGE_SSR_REQ,
  GET_META_KEYWORDS_PAGE_SSR_RES,
  GET_META_TITLE_PAGE_SSR_ERR,
  GET_META_TITLE_PAGE_SSR_REQ,
  GET_META_TITLE_PAGE_SSR_RES,
  GET_META_VIEWPORT_PAGE_SSR_ERR,
  GET_META_VIEWPORT_PAGE_SSR_REQ,
  GET_META_VIEWPORT_PAGE_SSR_RES,
  GET_PAGES_ERR,
  GET_PAGES_REQ,
  GET_PAGES_RES,
  GET_PURE_PAGE_ABOUT_US_SSR_ERR,
  GET_PURE_PAGE_ABOUT_US_SSR_REQ,
  GET_PURE_PAGE_ABOUT_US_SSR_RES,
  GET_PURE_PAGE_ERR,
  GET_PURE_PAGE_REQ,
  GET_PURE_PAGE_RES,
  GET_SLIDER_PAGE_SSR_ERR,
  GET_SLIDER_PAGE_SSR_REQ,
  GET_SLIDER_PAGE_SSR_RES,
  GET_WAR_PAGE_SSR_ERR,
  GET_WAR_PAGE_SSR_REQ,
  GET_WAR_PAGE_SSR_RES
} from "../constants";
// ##### 25-01-09 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-02-26 Komarov
import { uniLog } from "../../../server/helpers/uniLog";
import config from "../config";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// ##### 24-03-21 Komarov
export const getPages = (lang) => async (dispatch) => {
  dispatch({ type: GET_PAGES_REQ });
  // console.log('REDUX_LOCALE:',lang)

  // ##### 25-01-09 Komarov
  const url = getUrl("classifier", `classifier=sitepage&language=${lang}`);

  // ##### 25-02-26 Komarov
  uniLog("pages.js", "axios:src/Redux/actions/pages.js:getPages, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_PAGES_RES,
    typeErr: GET_PAGES_ERR,
    dispatch,
  });
};

// ##### 23-08-18 Komarov
export const getPurePage =
  ({
    id,
    lang,
    deviceType = "desktop",
    sitepagecity_type_id = 56,
    pageType = "sitepages",
    // ##### 24-09-16 Komarov
    user_id,
  }) =>
  async (dispatch) => {
    // console.log("ID_TEST",getState())

    // ##### 25-03-07 Komarov
    dispatch({ type: GET_PURE_PAGE_REQ });

    const url =
      (pageType === "sitepagecitycontent" &&
        getUrl(
          "sitepagecitycontent",
          `sitepagecity_id=${id}&sitepagecity_type_id=${sitepagecity_type_id}&language=${lang}&deviceType=${deviceType}&cityview=1&user_id=${user_id}`,
        )) ||
      getUrl(
        "sitepagecontent",
        `id=${id}&language=${lang}&deviceType=${deviceType}`,
      );

    // ##### 25-03-05 Komarov
    uniLog(
      "pages.js",
      "axios:src/Redux/actions/pages.js:getPurePage, url:",
      url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await getSavingDataClient({
      smartUrl: url,
      typeRes: GET_PURE_PAGE_RES,
      typeErr: GET_PURE_PAGE_ERR,
      dispatch,
    });
  };

// ##### 25-03-18 Komarov
// TODO: Изменить название этой функции (и соотв. константб которые определяют тип action)
// ##### 25-03-25 Komarov: поставил id по умолчанию 209, потому что при смене языка нужно запросить Главную страницу, id для которой не указывается в URL
export const getPurePageSsr =
  (id = 209, deviceType = "desktop", lang = config.defaultLang) =>
  async (dispatch) => {
    // if (id === 221) debugger;

    // ##### 25-03-06 Komarov
    let typeReq;
    let typeRes;
    let typeErr;
    switch (id) {
      case 2:
        typeReq = GET_PURE_PAGE_ABOUT_US_SSR_REQ;
        typeRes = GET_PURE_PAGE_ABOUT_US_SSR_RES;
        typeErr = GET_PURE_PAGE_ABOUT_US_SSR_ERR;
        break;
      case 199:
        typeReq = GET_SLIDER_PAGE_SSR_REQ;
        typeRes = GET_SLIDER_PAGE_SSR_RES;
        typeErr = GET_SLIDER_PAGE_SSR_ERR;
        break;
      case 209:
        typeReq = GET_MAIN_PAGE_SSR_REQ;
        typeRes = GET_MAIN_PAGE_SSR_RES;
        typeErr = GET_MAIN_PAGE_SSR_ERR;
        break;
      case 210:
        typeReq = GET_WAR_PAGE_SSR_REQ;
        typeRes = GET_WAR_PAGE_SSR_RES;
        typeErr = GET_WAR_PAGE_SSR_ERR;
        break;
      case 219:
        typeReq = GET_META_CHARSET_PAGE_SSR_REQ;
        typeRes = GET_META_CHARSET_PAGE_SSR_RES;
        typeErr = GET_META_CHARSET_PAGE_SSR_ERR;
        break;
      case 220:
        typeReq = GET_META_VIEWPORT_PAGE_SSR_REQ;
        typeRes = GET_META_VIEWPORT_PAGE_SSR_RES;
        typeErr = GET_META_VIEWPORT_PAGE_SSR_ERR;
        break;
      case 221:
        typeReq = GET_META_TITLE_PAGE_SSR_REQ;
        typeRes = GET_META_TITLE_PAGE_SSR_RES;
        typeErr = GET_META_TITLE_PAGE_SSR_ERR;
        break;
      case 304:
        typeReq = GET_META_KEYWORDS_PAGE_SSR_REQ;
        typeRes = GET_META_KEYWORDS_PAGE_SSR_RES;
        typeErr = GET_META_KEYWORDS_PAGE_SSR_ERR;
        break;
      case 305:
        typeReq = GET_META_DESCRIPTION_PAGE_SSR_REQ;
        typeRes = GET_META_DESCRIPTION_PAGE_SSR_RES;
        typeErr = GET_META_DESCRIPTION_PAGE_SSR_ERR;
        break;
      default:
        // ##### 25-03-31 Komarov: если не найден id, то записываем результат в поле Redux pages.purepage
        typeReq = GET_PURE_PAGE_REQ;
        typeRes = GET_PURE_PAGE_RES;
        typeErr = GET_PURE_PAGE_ERR;
    }

    dispatch({ type: typeReq });

    const url = getUrl(
      "sitepagecontent",
      `id=${id}&language=${lang}&deviceType=${deviceType}`,
    );
    // ##### 25-04-10 Komarov
    uniLog(
      "pages.js",
      "axios:src/Redux/actions/pages.js:getPurePageSsr, url:",
      url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await getSavingDataClient({
      smartUrl: url,
      typeRes,
      typeErr,
      dispatch,
    });
  };

// ##### 25-04-07 Komarov
/*export const getPreloadedPage =
  (id, deviceType = "desktop", lang = config.defaultLang) =>
  async (dispatch) => {
    // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
    const processDataFunction = async (res) => {
      return {
        id,
        data: await res,
      };
    };

    // ##### 25-03-06 Komarov
    const typeReq = GET_PRELOADED_PAGE_REQ;
    const typeRes = GET_PRELOADED_PAGE_RES;
    const typeErr = GET_PRELOADED_PAGE_ERR;

    const url = getUrl(
      "sitepagecontent",
      `id=${id}&language=${lang}&deviceType=${deviceType}`,
    );
    // ##### 25-02-26 Komarov
    uniLog(
      "pagesServer.js",
      "axios:src/Redux/actions/pages.js:getPreloadedPage, url:",
      url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await getSavingDataClient({
      smartUrl: url,
      typeRes,
      typeErr,
      dispatch,
      processDataFunction,
    });

    // ##### 25-04-01 Komarov: устанавлиаем признак того, что страница загрузилась (тип action typeReq), только ПОСЛЕ того, как онв загрузилась, поэтому этот dispatch я поставил после функции, которая диспатчит тип action typeRes
    dispatch({ type: typeReq, payload: id });
  };*/

// ##### 25-04-07 Komarov
/*export const getPreloadedCityPage =
  (id, deviceType = "desktop", lang = config.defaultLang, user_id) =>
  async (dispatch) => {
    // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
    const processDataFunction = async (res) => {
      return {
        id,
        data: await res,
      };
    };

    // ##### 25-03-06 Komarov
    const typeReq = GET_PRELOADED_CITY_PAGE_REQ;
    const typeRes = GET_PRELOADED_CITY_PAGE_RES;
    const typeErr = GET_PRELOADED_CITY_PAGE_ERR;

    const url = getUrl(
      "sitepagecitycontent",
      `sitepagecity_id=${id}&sitepagecity_type_id=56&language=${lang}&deviceType=${deviceType}&cityview=1&user_id=${user_id}`,
    );
    // ##### 25-02-26 Komarov
    uniLog(
      "pagesServer.js",
      "axios:src/Redux/actions/pages.js:getPreloadedPage, url:",
      url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await getSavingDataClient({
      smartUrl: url,
      typeRes,
      typeErr,
      dispatch,
      processDataFunction,
    });

    // ##### 25-04-01 Komarov
    dispatch({ type: typeReq, payload: id });
  };*/
